import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Card from "../../components/shared/card"
import Grid from "../../components/shared/grid"

export default function Jednodjelne() {
  return (
    <Layout>
      <SEO title="Dvodjelne slike" />
      <h1 style={{ textAlign: "center" }}>Dvodjelne slike</h1>
      <StaticQuery
        query={fetchProducts}
        render={data => (
          <Grid>
            {data.allSanityProduct.nodes.map(product => (
              <Link
                key={product.defaultProductVariant.sku}
                to={product.slug.current}
              >
                <Card
                  text={product.title}
                  src={product.defaultProductVariant.images.asset.fluid.srcWebp}
                />
              </Link>
            ))}
          </Grid>
        )}
      />
    </Layout>
  )
}

export const fetchProducts = graphql`
  query {
    allSanityProduct(
      filter: { categories: { elemMatch: { title: { eq: "Dvodjelne" } } } }
      sort: { fields: title, order: DESC }
    ) {
      nodes {
        title
        slug {
          current
        }
        defaultProductVariant {
          sku
          images {
            asset {
              fluid(maxWidth: 500) {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
